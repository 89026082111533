import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import FirstScreen from './FirstScreen';
import './index.css';
import '@/assets/styles/reset.scss';
import reportWebVitals from './reportWebVitals';
import Router from './router/index';

function listen() {
  // if (document.readyState == 'complete') { // 资源加载完成
  if (true) {
    ReactDom.render(
      <React.StrictMode>
        <Router />
      </React.StrictMode>,
      document.getElementById('root')
    )
  } else { // 资源加载中
    ReactDom.render(
      <BrowserRouter>
        <Route path="/" component={FirstScreen} />
      </BrowserRouter>,
      document.getElementById('root')
    )
  }
}

document.onreadystatechange = listen

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
reportWebVitals(console.log)